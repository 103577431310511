angular.module('psModule')
    .controller('PortfolioanalysisCtrl', function ($scope, $uibModal, $transition$, $sce, $rootScope) {
        const useSecurities = $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES === true;
        const values = $transition$.params().values;
        const isinCodes = $transition$.params().isinCodes;
        $scope.authorized = function () {
            return useSecurities ? $scope.user.fullAccess : $scope.user.broker;
        };
        $scope.handleFundLinkClick = function(event) {
            const fund = event.detail;
            $uibModal.open({
                template:'<div><div class="modal-header mm-modal-header"><p class="text-right mm-modal-close" ng-click="$dismiss()"><i class="fa fa-times"></i></p></div><div class="modal-body"><iframe ng-if="!useSecurities" id="detailsFrame" ng-src="{{detailsUrl}}" style="zoom:0.60" width="99.6%" height="1500" frameborder="0"></iframe><mm-fund-analysis-ce ng-if="useSecurities" ng-prop-isin="fund.isin" ng-prop-currency="fund.currency"><div class="col-md-12"><div class="alert alert-danger" translate="ps_fundanalysis_securities_not_available"></div></div></mm-fund-analysis-ce></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'ps_close\' | translate}}</button></div></div>',
                controller: 'FunddetailsmodalCtrl',
                size: 'lg',
                resolve: {
                    fund: function() { return fund; }
                }
            });
        };
        $scope.analysisInfo = {
            funds: {}
        };
        if (isinCodes && values) {
            if (useSecurities) {

                const isinCodesList = isinCodes.split("|");
                const valuesList = values.split("|");
                $scope.holdings = isinCodesList
                    .map((productCode, index) => ({productCode, codeType:"ISIN", currencyCode: "SEK", type: "HOLDING_CAPITAL", value: valuesList[index]}));
                // TODO: Nedanstående loggning är kvarlämnad med avsikt. Kan tas bort när vi ser att  december-24 releasen av Securities fungerar som avsett.
                console.log("$scope.holdings", $scope.holdings);
            } else {

                if (!$scope.authorized()) {
                    $scope.analysisInfo.funds.isinCodes = isinCodes;
                    $scope.analysisInfo.funds.values = values;
                    const urlTemplate = _.template($rootScope.config.xray.xrayUrlTemplate);
                    $scope.analysisInfo.url = $sce.trustAsResourceUrl(urlTemplate($scope.analysisInfo.funds));
                }
            }
        } else {
            $scope.analysisInfo.missing = true;
        }

        $scope.useSecurities = $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES === true;

    });
