angular.module('psModule').controller('PortfoliosCtrl', function($scope,  $uibModal) {

    $scope.insuranceCompanies = [
        {name: 'Folksam', orgNo: '502006-1585'},
        {name: 'Futur', orgNo: '516401-6643'},
        {name: 'Länsförsäkringar', orgNo: '516401-6627'},
        {name: 'Movestic', orgNo: '516401-6718'},
        {name: 'SEB', orgNo: '516401-8243'},
        {name: 'Skandia', orgNo: '516406-0948'},
        {name: 'SPP', orgNo: '516401-8599'},
        {name: 'Nordnet', orgNo: '16516406-0286'}
    ];
    $scope.useSecuritiesPortfolios = $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES_PORTFOLIOS === true;
    $scope.activeInsuranceCompany = null;
    $scope.filterCategories = "Lifepension;Maxfonder";
    $scope.handleFundLinkClick = function(event) {
        const fund = event.detail;
        $uibModal.open({
            template:'<div><div class="modal-header mm-modal-header"><p class="text-right mm-modal-close" ng-click="$dismiss()"><i class="fa fa-times"></i></p></div><div class="modal-body"><iframe ng-if="!useSecurities" id="detailsFrame" ng-src="{{detailsUrl}}" style="zoom:0.60" width="99.6%" height="1500" frameborder="0"></iframe><mm-fund-analysis-ce ng-if="useSecurities" ng-prop-isin="fund.isin" ng-prop-currency="fund.currency"><div class="col-md-12"><div class="alert alert-danger" translate="ps_fundanalysis_securities_not_available"></div></div></mm-fund-analysis-ce></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'ps_close\' | translate}}</button></div></div>',
            controller: 'FunddetailsmodalCtrl',
            size: 'lg',
            resolve: {
                fund: function() { return fund; }
            }
        });
    };

    });
